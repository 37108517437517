import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Knockout Academy
			</title>
			<meta name={"description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:title"} content={"Knockout Academy"} />
			<meta property={"og:description"} content={"Knockout Academy'de birinci sınıf boks eğitimini keşfedin. Uzman koçlarımızla becerilerinizi geliştirin, formunuzu iyileştirin ve hedeflerinize ulaşın."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35a6995d120018f291cc/images/2-1.jpg?v=2024-08-01T08:54:09.197Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Headernew />
		<Section background="--color-dark" padding="80px 0 0 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline3"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Boks Yolculuğunuza Başlamaya Hazır mısınız?
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Becerilerinizi ve formunuzu dönüştürme fırsatını kaçırmayın. Bugün Knockout Academy'ye katılın ve uzman koçluğundan ve destekleyici bir topluluktan faydalanın. Ücretsiz deneme dersine kaydolmak için bizi arayın!
				</Text>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Numunebağ Caddesi Özgür Sokak,{" "}
					<br />
					Dilek Çk No: 7, 34050 Bayrampaşa, Türkiye
					<br />
					<br />
					+902125441555
				</Text>
			</Box>
		</Section>
		<Section padding="88px 0 88px 0" quarkly-title="Form-7">
			<Override slot="SectionContent" align-items="center" lg-align-items="flex-start" />
			<Box margin="0px 0px 40px 0px" lg-padding="0px 0px 0px 0px" padding="0px 170px 0px 170px">
				<Text
					margin="0px 0px 30px 0px"
					font="--headline2"
					color="--light"
					sm-font="--headline2"
					sm-text-align="left"
					lg-padding="0px 0px 0px 0px"
					text-align="center"
					lg-max-width="640px"
					lg-text-align="left"
				>
					İletişim formu
				</Text>
				<Text
					font="--lead"
					color="--lightD1"
					lg-padding="0px 0px 0px 0px"
					sm-text-align="left"
					text-align="center"
					lg-max-width="640px"
					lg-text-align="left"
					max-width="640px"
				>
					Danışma veya rezervasyon için iletişim bilgilerinizi bırakın, kısa süre içinde sizinle iletişime geçelim
				</Text>
			</Box>
			<Box
				padding="40px 40px 60px 40px"
				background="--color-primary"
				border-radius="24px"
				max-width="940px"
				width="100%"
				lg-max-width="480px"
				lg-padding="40px 40px 40px 40px"
			>
				<Components.QuarklycommunityKitNetlifyForm>
					<Override
						slot="Form"
						flex-direction="row"
						display="flex"
						width="100%"
						align-items="flex-end"
						justify-content="center"
					/>
					<Box
						align-items="flex-end"
						md-align-items="center"
						display="flex"
						justify-content="center"
						lg-flex-wrap="wrap"
						lg-justify-content="flex-end"
					>
						<Box
							margin="0px 30px 0px 0px"
							md-width="100%"
							display="flex"
							flex-direction="column"
							sm-margin="0px 0px 0px 0px"
							lg-margin="0px 0px 30px 0px"
							md-margin="0px 0px 30px 0px"
							flex="1 1 auto"
							width="100%"
						>
							<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
								İsim
							</Text>
							<Input
								border-radius="8px"
								padding="11px 16px 11px 16px"
								required={false}
								font="--lead"
								color="rgba(111, 131, 159, 0.5)"
								border-width="0px"
								placeholder-color="rgba(111, 131, 159, 0.5)"
								placeholder="Mahir"
								name="Name"
								type="text"
								sm-width="100%"
								lg-width="100%"
								lg-margin="0px 0px 10px 0px"
								flex="1 1 auto"
								width="100%"
								margin="0px 0px 0px 0px"
							/>
						</Box>
						<Box
							margin="0px 30px 0px 0px"
							sm-margin="0px 0px 30px 0px"
							lg-margin="0px 0px 30px 0px"
							md-width="100%"
							flex="1 1 auto"
							width="100%"
							display="flex"
							flex-direction="column"
						>
							<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
								E-posta
							</Text>
							<Input
								color="rgba(111, 131, 159, 0.5)"
								lg-margin="0px 0px 10px 0px"
								border-width="0px"
								placeholder="name@mail.com"
								flex="1 1 auto"
								name="Email"
								required={false}
								width="100%"
								padding="11px 16px 11px 16px"
								type="email"
								lg-width="100%"
								placeholder-color="rgba(111, 131, 159, 0.5)"
								margin="0px 0px 0px 0px"
								font="--lead"
								sm-width="100%"
								border-radius="8px"
							/>
						</Box>
						<Box
							margin="0px 30px 0px 0px"
							sm-margin="0px 0px 30px 0px"
							lg-margin="0px 0px 30px 0px"
							md-width="100%"
							flex="1 1 auto"
							width="100%"
							display="flex"
							flex-direction="column"
						>
							<Text margin="0px 0px 8px 0px" color="--light" font="--lead">
								Telefon
							</Text>
							<Input
								color="rgba(111, 131, 159, 0.5)"
								lg-margin="0px 0px 10px 0px"
								border-width="0px"
								placeholder="+90 (5__) ___ __ __"
								flex="1 1 auto"
								name="Email"
								required={false}
								width="100%"
								padding="11px 16px 11px 16px"
								type="tel"
								lg-width="100%"
								placeholder-color="rgba(111, 131, 159, 0.5)"
								margin="0px 0px 0px 0px"
								font="--lead"
								sm-width="100%"
								border-radius="8px"
							/>
						</Box>
						<Button
							font="--lead"
							padding="12px 64px 12px 64px"
							background="--color-darkL2"
							border-radius="8px"
							sm-display="block"
							sm-width="100%"
						>
							Gönder
						</Button>
					</Box>
				</Components.QuarklycommunityKitNetlifyForm>
			</Box>
		</Section>
		<Components.Footernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});